export default [
  {
    path: '/seo_pages',
    name: 'seo_pages-index',
    component: () => import('@/views/seo_pages/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.seo_pages',
      breadcrumb: [
        {
          text: 'administration.navigation.seo_pages',
          active: true,
        },
      ],
      resource: 'seo_pages',
      action: 'index',
    },
  },
  {
    path: '/seo_pages/create',
    name: 'seo_pages-store',
    component: () => import('@/views/seo_pages/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.seo_pages',
      breadcrumb: [
        {
          text: 'administration.navigation.seo_pages',
          to: { name: 'seo_pages-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'seo_pages',
      action: 'store',
    },
  },
  {
    path: '/seo_pages/edit/:id',
    name: 'seo_pages-update',
    component: () => import('@/views/seo_pages/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.seo_pages',
      breadcrumb: [
        {
          text: 'administration.navigation.seo_pages',
          to: { name: 'seo_pages-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'seo_pages',
      action: 'update',
    },
  },
  {
    path: '/gen_faq',
    name: 'gen_faq-index',
    component: () => import('@/views/gen_faq/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.gen_faq',
      breadcrumb: [
        {
          text: 'administration.navigation.gen_faq',
          active: true,
        },
      ],
      resource: 'seo_pages',
      action: 'index',
    },
  },
  {
    path: '/gen_faq/create',
    name: 'gen_faq-store',
    component: () => import('@/views/gen_faq/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.gen_faq',
      breadcrumb: [
        {
          text: 'administration.navigation.gen_faq',
          to: { name: 'gen_faq-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'seo_pages',
      action: 'store',
    },
  },
  {
    path: '/gen_faq/edit/:id',
    name: 'gen_faq-update',
    component: () => import('@/views/gen_faq/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.gen_faq',
      breadcrumb: [
        {
          text: 'administration.navigation.gen_faq',
          to: { name: 'gen_faq-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'seo_pages',
      action: 'update',
    },
  },
]
