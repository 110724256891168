export default [
  {
    path: '/fm',
    name: 'fm',
    component: () => import('@/views/fm/List'),
    meta: {
      pageTitle: 'FileManager',
      breadcrumb: [
        {
          text: 'administration.navigation.system',
        },
        {
          text: 'FileManager',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },

  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/system/settings/List'),
    meta: {
      pageTitle: 'administration.navigation.settings',
      breadcrumb: [
        {
          text: 'administration.navigation.system',
        },
        {
          text: 'administration.navigation.settings',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
  {
    path: '/languages',
    name: 'languages',
    component: () => import('@/views/system/languages/List'),
    meta: {
      pageTitle: 'administration.navigation.languages',
      breadcrumb: [
        {
          text: 'administration.navigation.system',
        },
        {
          text: 'administration.navigation.languages',
          active: true,
        },
      ],
      resource: 'languages',
      action: 'index',
    },
  },
  {
    path: '/translations',
    name: 'translations',
    component: () => import('@/views/system/translations/List'),
    meta: {
      pageTitle: 'administration.navigation.translations',
      breadcrumb: [
        {
          text: 'administration.navigation.system',
        },
        {
          text: 'administration.navigation.translations',
          active: true,
        },
      ],
      resource: 'translations',
      action: 'index',
    },
  },

  {
    path: '/shops',
    name: 'shops-index',
    component: () => import('@/views/system/shops/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.shops',
      breadcrumb: [
        {
          text: 'administration.navigation.shops',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'index',
    },
  },
  {
    path: '/shops/create',
    name: 'shops-store',
    component: () => import('@/views/system/shops/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.shops',
      breadcrumb: [
        {
          text: 'administration.navigation.shops',
          to: { name: 'shops-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'store',
    },
  },
  {
    path: '/shops/edit/:id',
    name: 'shops-update',
    component: () => import('@/views/system/shops/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.shops',
      breadcrumb: [
        {
          text: 'administration.navigation.shops',
          to: { name: 'shops-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'shops',
      action: 'update',
    },
  },

  {
    path: '/payment_methods',
    name: 'payment_methods-index',
    component: () => import('@/views/system/payment_methods/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.payment_methods',
      breadcrumb: [
        {
          text: 'administration.navigation.payment_methods',
          active: true,
        },
      ],
      resource: 'payment_methods',
      action: 'index',
    },
  },
  {
    path: '/payment_methods/create',
    name: 'payment_methods-store',
    component: () => import('@/views/system/payment_methods/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.payment_methods',
      breadcrumb: [
        {
          text: 'administration.navigation.payment_methods',
          to: { name: 'payment_methods-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'payment_methods',
      action: 'store',
    },
  },
  {
    path: '/payment_methods/edit/:id',
    name: 'payment_methods-update',
    component: () => import('@/views/system/payment_methods/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.payment_methods',
      breadcrumb: [
        {
          text: 'administration.navigation.payment_methods',
          to: { name: 'payment_methods-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'payment_methods',
      action: 'update',
    },
  },

  {
    path: '/delivery_methods',
    name: 'delivery_methods-index',
    component: () => import('@/views/system/delivery_methods/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.delivery_methods',
      breadcrumb: [
        {
          text: 'administration.navigation.delivery_methods',
          active: true,
        },
      ],
      resource: 'delivery_methods',
      action: 'index',
    },
  },
  {
    path: '/delivery_methods/create',
    name: 'delivery_methods-store',
    component: () => import('@/views/system/delivery_methods/Create.vue'),
    meta: {
      pageTitle: 'administration.navigation.delivery_methods',
      breadcrumb: [
        {
          text: 'administration.navigation.delivery_methods',
          to: { name: 'delivery_methods-index' },
        },
        {
          text: 'general.add',
          active: true,
        },
      ],
      resource: 'delivery_methods',
      action: 'store',
    },
  },
  {
    path: '/delivery_methods/edit/:id',
    name: 'delivery_methods-update',
    component: () => import('@/views/system/delivery_methods/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.delivery_methods',
      breadcrumb: [
        {
          text: 'administration.navigation.delivery_methods',
          to: { name: 'delivery_methods-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'delivery_methods',
      action: 'update',
    },
  },

  {
    path: '/seo_templates',
    name: 'seo_templates-index',
    component: () => import('@/views/system/seo_templates/List.vue'),
    meta: {
      pageTitle: 'administration.navigation.seo_templates',
      breadcrumb: [
        {
          text: 'administration.navigation.seo_templates',
          active: true,
        },
      ],
      resource: 'seo',
      action: 'index',
    },
  },
  {
    path: '/seo_templates/edit/:id',
    name: 'seo_templates-update',
    component: () => import('@/views/system/seo_templates/Edit.vue'),
    meta: {
      pageTitle: 'administration.navigation.seo_templates',
      breadcrumb: [
        {
          text: 'administration.navigation.seo_templates',
          to: { name: 'seo_templates-index' },
        },
        {
          text: 'general.edit',
          active: true,
        },
      ],
      resource: 'seo',
      action: 'update',
    },
  },

  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/system/search/Settings.vue'),
    meta: {
      pageTitle: 'administration.navigation.search',
      breadcrumb: [
        {
          text: 'administration.navigation.system',
        },
        {
          text: 'administration.navigation.search',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
  {
    path: '/search/history',
    name: 'search-history',
    component: () => import('@/views/system/search/history/List'),
    meta: {
      pageTitle: 'administration.navigation.search_history',
      breadcrumb: [
        {
          text: 'administration.navigation.system',
        },
        {
          text: 'administration.navigation.search',
        },
        {
          text: 'administration.navigation.search_history',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
  {
    path: '/search/relations',
    name: 'search-relations',
    component: () => import('@/views/system/search/relations/List'),
    meta: {
      pageTitle: 'administration.navigation.search_relations',
      breadcrumb: [
        {
          text: 'administration.navigation.system',
        },
        {
          text: 'administration.navigation.search',
        },
        {
          text: 'administration.navigation.search_relations',
          active: true,
        },
      ],
      resource: 'settings',
      action: 'index',
    },
  },
]
